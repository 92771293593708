import React from "react";
import { useNavigate } from "react-router-dom";

export default function Dashboard(props) {
  const navigate = useNavigate();
  return <DashboardComp {...props} navigate={navigate} />;
}

class DashboardComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="content-wrapper">
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">トップページ</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn btn-block btn-info pt-4 pb-4"
                      onClick={() => {
                        this.props.navigate("/reception_car_type");
                      }}
                    >
                      <i className="nav-icon fas fa-calendar-plus"></i> 予約受付
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-block btn-info pt-4 pb-4"
                      onClick={() => {
                        this.props.navigate("/confirmation_list");
                      }}
                    >
                      <i className="nav-icon fas fa-calendar-alt"></i> 予約確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-default">
              <div className="card-body">
                <div className="callout callout-info">
                  <h5>お　知　ら　せ</h5>
                  <p>
                    振興会の予約確認窓口よりお知らせになります。
                    <br />
                    自動車技術総合機構より下記の期間の１Ｒの検査開始時間を早めるとの連絡があり、振興会の１Ｒ予約確認時間も早めでの時間に対応とさせていただく事となり、それに伴い商工組合窓口、予備検査場も期間中は早めの対応になりますので、お知らせいたします。
                    <br />
                    <div
                      style={{
                        "font-weight": "bold",
                        "font-size": "1.4rem",
                        color: "red",
                      }}
                    >
                      (軽自動車の予約確認時間は通常どおりです。)
                    </div>
                  </p>
                  <p className="col-12">
                    <div className="col-12" style={{ "text-align": "center" }}>
                      記
                      <br />
                      <table
                        className="table table-sm table-bordered table-hover table-head-fixed text-nowrap"
                        style={{ width: "100%" }}
                      >
                        <tr>
                          <th></th>
                          <th>３/１７(月)</th>
                          <th>３/１８(火)</th>
                          <th>３/１９(水)</th>
                          <th>３/２１(金)</th>
                        </tr>
                        <tr>
                          <th>振興会、商工組合開館</th>
                          <td>7：55</td>
                          <td>7：55</td>
                          <td>7：55</td>
                          <td>7：55</td>
                        </tr>
                        <tr>
                          <th>陸運事務所予約確認</th>
                          <td>8：00</td>
                          <td>8：00</td>
                          <td>8：00</td>
                          <td>8：00</td>
                        </tr>
                        <tr>
                          <th>商工組合重量税・ 物品販売窓口</th>
                          <td>7：55</td>
                          <td>7：55</td>
                          <td>7：55</td>
                          <td>7：55</td>
                        </tr>
                        <tr>
                          <th>予備検査場</th>
                          <td>7：30</td>
                          <td>7：30</td>
                          <td>7：30</td>
                          <td>7：30</td>
                        </tr>
                      </table>
                    </div>
                    <span
                      style={{
                        "font-weight": "bold",
                        "font-size": "1.2rem",
                        color: "red",
                        "border-bottom": "solid 1px red",
                      }}
                    >
                      ※キャンセル待ちについては当会の各システム上の都合により今までどおり、
                      8：15からの受付となります。
                      急なご連絡になり申し訳ございませんが、ご協力お願いいたします。
                    </span>
                    <br />
                    沖縄県自動車整備振興会　指導課
                  </p>
                </div>

                <div className="callout callout-info">
                  <h5>【陸事：車検予約に関するお知らせ】</h5>
                  <p>
                    令和７年３月１０日（月）より、登録自動車（陸事）の車検予約
                    キャンセル待ち受付がWEBから可能となります！
                    <br />
                    兼ねてより車検予約のキャンセル待ちを電話及び窓口で承っておりましたが、
                    この度インターネットを経由したキャンセル待ちの受付を開始させていただく運びとなりました。（※振興会予約枠のみ）
                    <br />
                    尚、電話でのキャンセル待ちは併用・継続して受付いたします。
                    <br />
                    <br />
                    詳しくは　
                    <a
                      target="_blank"
                      href="https://www.jaspa-okinawa.or.jp/content/files/SHIDOUKA/20250304/20250304-045217.pdf"
                    >
                      こちら
                    </a>
                    　から
                  </p>
                </div>

                <div className="callout callout-info">
                  <h5>検査コースの閉鎖について</h5>
                  <p>
                    検査場をご利用の皆様には独立行政法人自動車技術総合機構の業務につきまして、平素よりご理解とご協力を賜り感謝申し上げます。
                    <br />
                    検査機器修繕のため、検査コースを閉鎖します。
                    <br />
                    ２コース体制の中、皆様にご迷惑をおかけいたしますが自動車検査の精度維持のため、校正が必要となっております。
                    <br />
                    ご不便をおかけすることになりますが、皆様のご理解をお願いいたします。
                    <br />
                    <br />
                    <div
                      style={{
                        "font-weight": "bold",
                        "font-size": "1.2rem",
                        color: "red",
                      }}
                    >
                      【機器修繕】２コース体制
                      <br />
                      <br />
                      ３月１７日(月)～１９日（水）
                      <br />
                      （１コース閉鎖）
                    </div>
                  </p>
                </div>

                <div className="callout callout-info">
                  <h5>【車検予約に関するお知らせ】</h5>
                  <p>
                    令和６年１２月２日（月）車検予約分より令和６年１２月２日（月）車検予約分より
                    <br />
                    <div
                      style={{
                        "font-weight": "bold",
                        "font-size": "1.2rem",
                      }}
                    >
                      電話・WEB 共に
                      <span
                        style={{
                          "font-size": "1.5rem",
                          color: "red",
                          "border-bottom": "solid 1px #000000",
                        }}
                      >
                        キャンセルが原則不可
                      </span>
                      となります。
                    </div>
                    <br />
                    <br />
                    令和６年９月１３日(金)に開かれた認証部会にて、下記の通り可決されましたのでご報告いたします。
                    <br />
                    会員の皆様におかれましてはご不便をおかけいたしますが、改善の様子が見られない見込み予約への対応策として実施する運びとなりましたので、ご理解・ご協力の程よろしくお願いいたします。
                    <br />
                    <br />
                    <a
                      href="/pdf/jaspa_okinawa_no_cancellation.pdf"
                      target="_blank"
                    >
                      キャンセル不可案内文書（１１月号掲載）
                    </a>
                  </p>
                </div>
                {/*
                <div className="callout callout-info">
                  <h5>検査コースについて</h5>
                  <p>
                    陸運事務所の検査コースについてお知らせいたします。
                    <br />
                    <span
                      style={{
                        "border-bottom": "solid 1px #000000",
                      }}
                    >
                      本年、６月より実施しておりました２コース体制が１０月１５日(火)をもって、一時解除されます。解除に伴い、予約枠が通常に戻るとともに、５ラウンドの枠が閉鎖となります。
                    </span>
                    <br />
                    予約システムも通常どおりの枠に戻しておりますので、お知らせいたします。
                    <br />
                    <br />
                    <span
                      style={{
                        "background-color": "yellow",
                        "border-bottom": "solid 1px #000000",
                        //"font-weight": "bold",
                      }}
                    >
                      令和６年１０月１５日より
                    </span>
                    <br />
                    <br />
                    <span
                      style={{
                        "background-color": "yellow",
                        "font-weight": "bold",
                        "font-size": "1.5rem",
                      }}
                    >
                      1R、2R、3R、4R、
                    </span>
                    <span
                      style={{
                        "text-decoration": "line-through",
                        "font-weight": "bold",
                        "font-size": "1.5rem",
                      }}
                    >
                      5R
                    </span>
                    <br />
                    ※ 別コースの修理時は再度２コース体制になります。
                    <br />
                    <br />
                    沖縄県自動車整備振興会指導課
                  </p>
                </div>
                <div className="callout callout-info">
                  <h5>検査コースの閉鎖について</h5>
                  <p>
                    検査場をご利用の皆様には独立行政法人自動車技術総合機構の業務につきまして、平素よりご理解とご協力を賜り感謝申し上げます。
                    <br />
                    検査機器精度維持のため、検査コースを閉鎖します。
                    <br />
                    ２コース体制の中、皆様にご迷惑をおかけいたしますが自動車検査の精度維持のため、校正が必要となっております。
                    <br />
                    ご不便をおかけすることになりますが、皆様のご理解をお願いいたします。
                    <br />
                    <br />
                    <span style={{ color: "red" }}>
                      【機器校正】１コース体制
                    </span>
                    <br />
                    <br />
                    <span style={{ color: "red" }}>
                      ８月８日（木）午後３時～
                    </span>
                    <br />
                    <span style={{ color: "red" }}>（１コース閉鎖）</span>
                    <br />
                    <span style={{ color: "red" }}>
                      ※1R検査開始8：30(予約確認8：00)
                    </span>
                    <br />
                    <span style={{ color: "red" }}>
                      キャンセル待ち8：15より
                    </span>
                    <br />
                    <br />
                    独立行政法人　自動車技術総合機構　沖縄事務所長
                  </p>
                </div>
                */}
                {/*
                <div className="callout callout-info">
                  <h5>（陸事）5R予約開始のお知らせ</h5>
                  <p>
                    令和６年６月１日より５Rの予約及びキャンセル待ちを電話にて承っておりましたが、
                    <br />
                    この度
                    <span style={{ "font-weight": "bold" }}>
                      令和６年８月１日（木）予約分より下記の通り電話・WEB共に５Ｒの予約が可能となります
                    </span>
                    のでご案内いたします。
                    <br />
                    <br />
                    尚、５Ｒは
                    <span
                      style={{
                        color: "red",
                        "border-bottom": "solid 1px red",
                        "font-weight": "bold",
                      }}
                    >
                      「継続検査」のみのご案内
                    </span>
                    です。
                    <br />
                    <span style={{ color: "red", "font-weight": "bold" }}>
                      システムの仕様上「中古新規」も予約が取れるようになっていますが、当日予約受付及び受検は出来ませんのでご注意ください。
                    </span>
                    <br />
                    <br />
                    他のラウンドのキャンセル待ちは引き続き電話にて承ります。ご相談・ご活用ください。
                    <br />
                    <span style={{ "font-weight": "bold" }}>
                      ※キャンセル待ちは当日のみのご案内となります。
                    </span>
                    <br />
                    前日等、前もって受けることは出来ませんので、ご了承の上ご相談ください。
                  </p>
                </div>
                */}
                {/*
                <div className="callout callout-info">
                  <h5>陸運事務所【４月分】２コース体制のお知らせ</h5>
                  <p>
                    下記の日程について、検査コースを閉鎖します。
                    <br />
                    ご不便をおかけいたしますが、ご理解とご協力お願いいたします。
                    <br />
                    <br />
                    【４月】２コース体制
                    <br />
                    １６日（火）終日（３コース閉鎖）
                    <br />
                    １７日（水）終日（３コース閉鎖）
                    <br />
                    １８日（木）終日（３コース閉鎖）
                  </p>
                </div>
                <div className="callout callout-info">
                  <h5>
                    年度末繁忙時期における検査・登録業務の円滑化に御協力ください。
                  </h5>
                  <p>
                    例年3月の年度末は、検査・登録関係の業務量が増し、窓口が大変混雑します。つきましては、下記の事項を遵守し業務の円滑化に御協力下さい。
                    <br />
                    <br />
                    1.検査については、可能な限り3月中旬迄に行うようにして下さい。
                    <br />
                    2.遅刻やラウンドの変更が無いようにしてください。
                    <br />
                    3.予約については、出来るだけ検査前日までに行って下さい。
                    <br />
                    また、
                    <span style={{ "border-bottom": "solid 1px red" }}>
                      見込み予約や無断キャンセルは絶対に行わないようにして下さい。
                    </span>
                    (ペナルティが科せられる場合があります。)
                    <br />
                    4.キャンセルは、検査前日(1Rと2Rは
                    <span style={{ color: "red" }}>15時</span>、3Rと4Rは
                    <span style={{ color: "red" }}>17時</span>
                    )までに確実に行って下さい。
                    <br />※
                    <span style={{ "border-bottom": "solid 1px red" }}>
                      締め切り時間の14時〜17時は電話が混みあいますので、可能な限り早めに対応をお願い致します。
                    </span>
                    <br />
                    <br />
                    問合せ先：指導課　098-877-7065(ガイダンス1番)
                  </p>
                </div>
                <div className="callout callout-info">
                  <h5>陸運事務所【２月分】２コース体制のお知らせ</h5>
                  <p>
                    下記の日程について、検査機器校正等のため検査コースを閉鎖します。
                    <br />
                    ご不便をおかけいたしますがご理解とご協力お願いいたします。
                    <br />
                    <br />
                    【２月】２コース体制
                    <br />
                    ７日（水）終日（３コース閉鎖）
                    <br />
                    １４日（火）終日（３コース閉鎖）
                  </p>
                </div>
                */}
                {/*
                <div className="callout callout-info">
                  <h5>陸運事務所【７月分】【８月分】２コース体制のお知らせ</h5>
                  <p>
                    下記の日程について、検査機器の校正等のため検査コースを閉鎖します。
                    <br />
                    ご不便をおかけすることになりますが、皆様のご理解をお願いいたします。
                    <br />
                    <br />
                    【７月】２コース体制
                    <br />
                    ２８日（金）終日（１コース閉鎖）
                    <br />
                    ３１日（月）終日（２コース閉鎖）
                    <br />
                    <br />
                    【８月】２コース体制
                    <br />
                    １日（火）終日（３コース閉鎖）
                  </p>
                </div>
                <div className="callout callout-info">
                  <h5>陸運事務所【１月分】２コース体制のお知らせ</h5>
                  <p>
                    下記の日程について、陸運事務所の検査機器の定期点検等のため検査コースを閉鎖します。
                    <br />
                    ご不便をおかけすることになりますが、皆様のご理解をお願いいたします。
                    <br />
                    <br />
                    【１月分】２コース体制
                    <br />
                    ４日（水）終日
                    <br />
                    ５日（木）終日
                    <br />
                    ２４日（火）終日
                    <br />
                    ２５日（水）終日
                  </p>
                </div>
                <div className="callout callout-info">
                  <h5>車検予約システムのウェブ予約ご利用開始のお知らせ</h5>
                  <p>
                    車検予約システムのインターネット予約は11/1(火)からご利用開始となります。よろしくお願い致します。
                  </p>
                </div>
                */}
                {/*
                <div className="callout callout-warning">
                  <h5>予約受付の制限中</h5>
                  <p>
                    現在、見込予約・ボイコットの規定を超えた為、予約受付が制限中となります。
                  </p>
                </div>
                <div className="callout callout-info">
                  <h5>お知らせ1タイトル</h5>
                  <p>お知らせ1内容○○○○○</p>
                </div>
                <div className="callout callout-info">
                  <h5>お知らせ2タイトル</h5>
                  <p>お知らせ2内容○○○○○</p>
                </div>
                <div className="callout callout-info">
                  <h5>お知らせ3タイトル</h5>
                  <p>お知らせ3内容○○○○○</p>
                </div>
                <div className="callout callout-info">
                  <h5>お知らせ4タイトル</h5>
                  <p>お知らせ4内容○○○○○</p>
                </div>
                <div className="callout callout-info">
                  <h5>お知らせ5タイトル</h5>
                  <p>お知らせ5内容○○○○○</p>
                </div>
                */}
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
